






































































import Vue from 'vue';
import TreatmentNotesCard from '@/components/cards/TreatmentNotesCard.vue';
import {mdiClose} from '@mdi/js';
import {mapState} from 'vuex';

export default Vue.extend({
  name: 'NotesDialog',
  components: {
    TreatmentNotesCard,
  },
  props: {
    showNotes: { type: Boolean, default: false }
  },
  data() {
    return {
      all: true,
      closeIcon: mdiClose,
      allowedToClose: true,
    }
  },
  mounted() {
    this.$store.dispatch('LOAD_NOTES');
  },
  computed: {
    ...mapState({
      // @ts-ignore
      notes: (state) => state.notes,
    }),
    notesAll() {
      return this.notes.length > 0 ? this.groupByNestedChildProperty(this.notes, 'treatment.uuid') : [];
    },
    display() {
      if (this.all) return this.notesAll;
      const myNotes = this.notes.filter(note => note.user.uuid === this.$store.state.auth.user.uuid);
      return this.groupByNestedChildProperty(myNotes, 'treatment.uuid');
    }
  },
  methods: {
    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    groupByNestedChildProperty(array, nestedChildProperty) {
      const grouped = {};

      array.forEach(obj => {
        const nestedChildValue = this.getObjectPropertyValue(obj, nestedChildProperty);

        if (!grouped[nestedChildValue]) {
          grouped[nestedChildValue] = [];
        }

        grouped[nestedChildValue].push(obj);
      });

      return grouped;
    },
    getObjectPropertyValue(obj, propertyPath) {
      const properties = propertyPath.split('.');
      let value = obj;

      for (let prop of properties) {
        value = value[prop];
      }
      return value;
    },
    closeModal() {
      if (this.allowedToClose) {
        this.$emit('close');
      }
    },
    setAllowedToClose(value: boolean) {
      this.allowedToClose = value;
    },
    isLast(treatmentUuid) {
      const treatmentCount = Object.keys(this.display).length;
      return treatmentUuid !== Object.keys(this.display)[treatmentCount - 1];
    }
  }
})
